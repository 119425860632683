import './external-link-selector.css'

import { tableRowClickEvent } from '@site/js/utils/tableRowClickEvent'

/**
 * column header template
 * - for load more functionality
 * @param {array} dynamicField
 */

type DynamicFieldPropsType = {
  value: string
  key: string
  link: boolean
  sortable?: true
}

type DataItem = {
  link?: string
  advisoryId?: string
  csafLink?: string
  title?: string
  fileName?: string
}

type DataPropsType = {
  dynamicFields: DynamicFieldPropsType[]
  componentName: string
  items: DataItem[]
  numberofrows: number
  totalNumber: number
}

type ConfigPropsType = {
  componentName: string
  numberofrows: number
  translations: {
    [key: string]: string
  }
  type?: string
}

const abbrStringMaxLength = 90
const abbrMaxItemsAsArray = 3

const dynamicFieldTemplate = (dynamicField: DynamicFieldPropsType) =>
  `<th class="c-filterable-list__table-th ${dynamicField.sortable ? 'c-filterable-list__table-th--is-sortable' : ''}" scope="col">${
    dynamicField.value
  }</th>`

/**
 * items template
 * - for load more functionality
 * @param data
 * @param config
 */
export const itemsTemplate = (data: DataPropsType, config: ConfigPropsType) => `
  ${data.items
    .map(
      (item, index) => `
      <tr class="c-filterable-list__table-row ${rowClass(data, config.numberofrows, index)}" data-dm="${config.componentName}.link">
      ${
        item.link || item.advisoryId
          ? data.dynamicFields
              .map(field => {
                let fieldValue = ''

                if (Array.isArray(item[field.key]) && item[field.key].length > 1) {
                  const displayedItems = item[field.key].slice(0, abbrMaxItemsAsArray)
                  fieldValue = displayedItems.join('<br/>')

                  if (item[field.key].length > abbrMaxItemsAsArray) {
                    const value = displayedItems.join('/')
                    const title = item[field.key].join('/')
                    fieldValue = `<span data-component="Abbreviation"
                    data-props:title="${title}"
                    data-props:value="${value}"
                    data-props:is-array="true"
                    data-props:abbr-string="..."
                    data-props:tool-tip-type="tooltip"
                    class="cmp-abbreviation"></span>`
                  }
                } else if (item[field.key]?.length > abbrStringMaxLength) {
                  fieldValue = `<span data-component="Abbreviation"
                  data-props:title="${item[field.key]}"
                  data-props:value="${item[field.key].slice(0, abbrStringMaxLength).trim()}"
                  data-props:abbr-string="..."
                  data-props:tool-tip-type="tooltip"
                  class="cmp-abbreviation"></span>`
                } else {
                  fieldValue = item[field.key]
                }

                const link = fieldValueTemplate(field, item, fieldValue)

                return `<td class="c-filterable-list__table-td">${link}</td>`
              })
              .join('')
          : ''
      }
      </tr>
    `,
    )
    .join('')}`

const fieldValueTemplate = (field: DynamicFieldPropsType, item: DataItem, fieldValue: string) => {
  if (field.link || field.key === 'csafLink') {
    const linkUrl = field.key === 'csafLink' ? item.csafLink : item.link
    const linkText = field.key === 'csafLink' ? '<span class="cmp-button__icon cmp-button__icon--download"></span>' : item?.title || item.fileName
    return `<a href="${linkUrl}" class="c-filterable-list__link" target="_blank" rel="noopener noreferrer">${linkText}</a>`
  } else {
    return fieldValue
  }
}

/**
 * Add on click event for the table rows using event delegation because of dynamic elements
 */

document.addEventListener('click', function (e) {
  const target = e.target as HTMLElement
  if (target.classList.contains('c-filterable-list__table-td')) {
    tableRowClickEvent(e, 'table-row-selectable')
  }
  if (target.classList.contains('c-filterable-list__fragmentslist-load-more-button')) {
    loadMoreOnlyForFragmentsListClickEvent(e)
  }
  if (target.closest('.sortable-header__controls')) {
    const listWrapper = target.closest('.list-wrapper')
    const sortedBy = listWrapper.querySelector('.c-filterbar__sub-sortedBy') as HTMLElement
    if (sortedBy) {
      sortedBy.style.display = 'none'
    }
  }
})

const loadMoreOnlyForFragmentsListClickEvent = (e: Event) => {
  const button = e.target as HTMLElement
  const parent = button.parentElement
  if (!parent) {
    return
  }
  const hiddenRows = [...parent.querySelectorAll('.c-filterable-list__table-row')].filter(
    row => getComputedStyle(row).getPropertyValue('display') === 'none',
  )

  hiddenRows.slice(0, numOfRows).forEach(row => {
    row.classList.remove('table-row--hidden')
  })

  if (parent.querySelectorAll('.table-row--hidden').length === 0) {
    button.style.display = 'none'
  }
}

/**
 * Check number of links in row and assign proper classnames
 * @param {*} data
 * @param numOfRows
 * @param rowId
 */

const rowClass = (data: DataPropsType, numOfRows: number, rowId: number) => {
  const numOfLinks = data.dynamicFields.filter(field => field.link).length
  const rowClasses = []

  if (numOfLinks >= 1) rowClasses.push('table-cell-selectable')

  if (rowId >= numOfRows) rowClasses.push('table-row--hidden')

  return rowClasses.join(' ')
}

let numOfRows: number

/**
 * list template
 * - has a ref="list" for load more functionality
 * @param {object} data
 * @param {object} config
 */
export const template = (data: DataPropsType, config: ConfigPropsType) => {
  const listType = config.type
  numOfRows = config.numberofrows

  return `<div class="c-filterable-list__${listType}" >
    <p class="c-filterable-list__results"></p>
    <div class="c-filterable-list__table-wrapper">
      <table ref="table" class="c-filterable-list__table">
        <thead class="c-filterable-list__table-thead">
          <tr class="c-filterable-list__table-row">
            ${data.dynamicFields.map(field => dynamicFieldTemplate(field)).join('')}
          </tr>
        </thead>
        <tbody ref="list" class="c-filterable-list__table-tbody">
          ${itemsTemplate(data, config)}
        </tbody>
      </table>
    </div>

    ${
      data.totalNumber > numOfRows
        ? `
    <button class="c-filterable-list__load-more-button c-filterable-list__button-secondary c-filterable-list__${listType}-load-more-button">
      ${config.translations.loadMore || 'Load More'}
      <span class="c-filterable-list__icon icon-keyboard_arrow_down icon--sm"></span>
    </button>`
        : ''
    }
  </div>
  `
}
